import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    flexGrow: 1,
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: 0,
    width: "100%",
    height: "100vh",
    alignItems: "center",
    display: "flex",
  },
});

class FullStore extends Component {
  render() {
    const { classes, t, image_full_store } = this.props;
    return (
      <Box>
        <Box
          className={classes.root}
          style={{ backgroundImage: `url(${image_full_store})` }}
        ></Box>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(FullStore));
